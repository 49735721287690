<template>
  <div class="products">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle
      :category="category"
      :selectedCategory="selectedCategory"
      :count="count"
      height="150px"
    />
    <v-container v-if="!categoryId">
      <v-row>
        <v-col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
        >
          <v-card
            outlined
            height="100%"
            :to="{
              name: 'Category',
              params: {
                pathMatch: category.slug
              },
              query: { categoryId: subCategory.categoryId }
            }"
          >
            <v-img
              contain
              :src="subCategory.metaData.category_info.ICON"
              :alt="subCategory.name"
              height="150"
              class="center-img"
            ></v-img>
            <v-card-title class="category-block-title">{{
              subCategory.name
            }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ProductListGrid
      v-else
      :parentCategoryId="category.categoryId"
      :categoryId="categoryId"
      :key="category.categoryId"
      :hidePromoFilter="true"
      hideFilterString="Categorie"
      @productsCount="updateCount"
    />
    <div class="white" v-if="footerText">
      <v-container> <div v-html="footerText"/></v-container>
    </div>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style scoped lang="scss">
.category-block-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
  justify-content: center;
  word-break: break-word;
}
.center-img {
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle";
import ProductListGrid from "@/components/product/ProductListGrid";

import categoryMixins from "~/mixins/category";

import toNumber from "lodash/toNumber";
import get from "lodash/get";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return { categoryId: null, count: null };
  },
  components: {
    CategoryTitle,
    ProductListGrid
  },
  computed: {
    // children() {
    //   return sortBy(this.category.category.children, c => -c.priority);
    // },
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    },
    footerText() {
      return get(this.category, "metaData.category_info.FOOTER_TEXT", null);
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  }
};
</script>
